<template>
  <div>
    <van-nav-bar title="聊天" />

    <van-form>
      <van-cell-group>
        <van-field
          ref="input"
          v-model="content"
          type="textarea"
          placeholder="请输入消息内容"
          rows="6"
        />
        <a class="btnMyEmoji" @click="myemojiClick">我的表情</a>
        <a class="btnEmoji" @click="emojiClick">表情</a>
      </van-cell-group>
      <van-grid>
        <van-grid-item @click="send('image')" icon="photo-o" text="图片" />
        <van-grid-item @click="send('video')" icon="video-o" text="视频" />
        <van-grid-item @click="send('transfer')" icon="peer-pay" text="转账" />
        <van-grid-item
          @click="send('text')"
          icon="upgrade"
          text="发送"
          class="btn-send"
        />
        <van-grid-item
          @click="send('redpacket')"
          icon="pending-payment"
          text="红包"
        />
        <van-grid-item @click="send('location')" icon="guide-o" text="位置" />
        <van-grid-item @click="send('card')" icon="idcard" text="名片" />
        <van-grid-item @click="send('file')" icon="orders-o" text="文件" />
      </van-grid>
    </van-form>

    <van-dialog
      v-model="showTransferDialog"
      title="转账"
      show-cancel-button
      :beforeClose="sendTransfer"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field
          v-model="money"
          label="转账金额"
          placeholder="请输入转账金额"
          type="number"
        />
        <van-field
          v-model="remark"
          label="转账备注"
          placeholder="请输入转账备注"
        />
      </van-cell-group>
    </van-dialog>

    <van-dialog
      v-model="showRedpacketDialog"
      title="红包"
      show-cancel-button
      :beforeClose="sendRedpacket"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field
          v-model="money"
          label="红包金额"
          placeholder="请输入红包金额"
          type="number"
        />
        <van-field
          v-model="remark"
          label="红包备注"
          placeholder="请输入红包备注"
        />
      </van-cell-group>
    </van-dialog>

    <van-dialog
      v-model="showImageDialog"
      title="图片"
      show-cancel-button
      :beforeClose="sendImage"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field name="uploader" label="图片上传">
          <template #input>
            <van-uploader
              v-model="image"
              max-count="1"
              :before-read="beforeUpload"
            />
          </template>
        </van-field>
      </van-cell-group>
    </van-dialog>

    <van-dialog
      v-model="showVideoDialog"
      title="视频"
      show-cancel-button
      :beforeClose="sendVideo"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field name="uploader" label="图片上传">
          <template #input>
            <van-uploader
              v-model="image"
              max-count="1"
              :before-read="beforeUpload"
            />
          </template>
        </van-field>
        <van-field v-model="time" label="视频时间" placeholder="01:20" />
      </van-cell-group>
    </van-dialog>

    <van-dialog
      v-model="showCardDialog"
      title="名片"
      show-cancel-button
      :beforeClose="sendCard"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field label="名片类型">
          <template #input>
            <van-radio-group v-model="cardType">
              <van-radio name="1" style="margin-bottom: 10px"
                >公众号名片</van-radio
              >
              <van-radio name="2">个人名片</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="nickname" label="名称" placeholder="请输入昵称" />
        <van-field name="uploader" label="头像">
          <template #input>
            <van-uploader
              v-model="image"
              max-count="1"
              :before-read="beforeUpload"
            />
          </template>
        </van-field>
        <van-field
          v-if="cardType == '2'"
          v-model="wechat"
          label="微信号"
          placeholder="请输入微信号"
        />
      </van-cell-group>
    </van-dialog>

    <van-dialog
      v-model="showFileDialog"
      title="文件"
      show-cancel-button
      :beforeClose="sendFile"
    >
      <van-cell-group style="margin-top: 10px; padding: 10px 0">
        <van-field
          readonly
          clickable
          label="文件类型"
          :value="fileType"
          placeholder="选择文件类型"
          @click="showFilePicker = true"
        />
        <van-field
          v-model="nickname"
          label="文件名称"
          placeholder="请输入文件名称"
        />
        <van-field
          v-model="size"
          label="文件大小"
          placeholder="请输入文件大小"
          type="number"
        />
        <van-field label="文件大小单位">
          <template #input>
            <van-radio-group v-model="sizeType">
              <van-radio name="1" style="margin-bottom: 10px">B</van-radio>
              <van-radio name="2" style="margin-bottom: 10px">KB</van-radio>
              <van-radio name="3">MB</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="微信电脑版">
          <template #input>
            <van-checkbox v-model="isPc" />
          </template>
        </van-field>
      </van-cell-group>
    </van-dialog>

    <van-popup v-model="popShow" closeable position="bottom">
      <van-swipe class="my-swipe" style="padding-top: 50px">
        <van-swipe-item v-for="group in emoji.length / 54" :key="group">
          <van-grid :column-num="9">
            <van-grid-item
              @click="emojiItemClick(emoji[(group - 1) * 54 + (value - 1)])"
              v-for="value in 54"
              :key="value"
            >
              <van-image :src="emoji[(group - 1) * 54 + (value - 1)].image" />
            </van-grid-item>
          </van-grid>
        </van-swipe-item>
      </van-swipe>
    </van-popup>

    <van-popup v-model="popMyemojiShow" closeable position="bottom">
      <div style="padding-top: 50px">
        <van-grid :column-num="6">
          <van-grid-item v-for="value in myemoji" :key="value.id">
            <van-image :src="value.url" @click="myemojiItemClick(value)" />
          </van-grid-item>
        </van-grid>
      </div>
    </van-popup>

    <van-popup v-model="showFilePicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="[
          'word文档',
          'excel文档',
          'pdf文档',
          'ppt文档',
          '音乐',
          'txt文档',
          '其他文件',
          'zip文件',
        ]"
        @cancel="showFilePicker = false"
        @confirm="onFileConfirm"
      />
    </van-popup>

    <van-overlay :show="popMapShow">
      <div class="mapBtns">
        <van-button type="danger" size="small" @click="popMapShow = false"
          >关闭</van-button
        >
        <van-button
          type="primary"
          size="small"
          style="margin-left: 10px"
          @click="sendMap"
          >发送</van-button
        >
      </div>
      <iframe
        class="ifm"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4UGBZ-62SWP-HJRDU-V6Q4W-HS4XQ-5EFKG&referer=myapp"
      />
    </van-overlay>

    <van-overlay :show="loading">
      <div class="loading">
        <van-loading v-if="showSpinner" type="spinner" />
        <span>{{ loadingText }}</span>
      </div>
    </van-overlay>

    <van-overlay :show="showWeixinGuid" class="weixin-guid">
      <img
        src="https://1jietu.com/Public/static/mobile/images/open-browser-tips.png"
        alt=""
        style="max-width: 90%; margin-right: 1%"
      />
    </van-overlay>
  </div>
</template>

<script>
import Emoji from "@/assets/emoji";
import Compressor from "compressorjs";
import { auth, send, myemoji } from "@/api/chat";
import {
  NavBar,
  Form,
  CellGroup,
  Button,
  Field,
  Grid,
  GridItem,
  Overlay,
  Loading,
  Dialog,
  Notify,
  Uploader,
  Popup,
  Image,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Picker,
  Checkbox,
} from "vant";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Field.name]: Field,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      sign_date: null,
      sign: null,
      showTransferDialog: false,
      showRedpacketDialog: false,
      showImageDialog: false,
      showVideoDialog: false,
      showCardDialog: false,
      showFileDialog: false,
      showFilePicker: false,
      showSpinner: true,
      popMyemojiShow: false,
      popMapShow: false,
      popShow: false,
      loading: false,
      loadingText: "正在连接服务器",
      content: "",
      token: null,
      money: "",
      remark: "",
      image: [],
      time: "01:20",
      emoji: Emoji,
      myemoji: [],
      cardType: "2",
      nickname: "",
      wechat: "",
      size: "",
      fileType: "",
      fileTypeIndex: null,
      sizeType: "1",
      isPc: false,
      location: null,
      showWeixinGuid: false,
    };
  },
  created() {
    const _this = this;
    const { token } = this.$route.query;

    if (!token) {
      this.connectError("参数错误");
      return;
    }

    console.log("Emoji", Emoji);

    this.token = token;
    this.connect();

    // console.log(window.navigator.geolocation.getCurrentPosition((a) => {
    //   console.log(a);
    // }));

    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        if (event.data && event.data.module == "locationPicker") {
          _this.location = event.data;
          console.log(_this.location);
        }
      },
      false
    );

    auth(token).then((res) => {
      if (res.code == 200) {
        this.sign_date = res.result.sign_date;
        this.sign = res.result.sign;
        this.loading = false;
        this.getMyemoji();
      } else {
        this.connectError(res.message);
      }
    });

    if (this.isWeiXin()) {
      this.showWeixinGuid = true;
    }
  },
  methods: {
    connect(msg) {
      msg = msg ? msg : "正在连接服务器";

      this.loading = true;
      this.showSpinner = true;
      this.loadingText = msg;
    },
    connectError(message) {
      this.loading = true;
      this.showSpinner = false;
      this.loadingText = message;
    },
    onFileConfirm(value, index) {
      this.fileType = value;
      this.fileTypeIndex = index;
      this.showFilePicker = false;
      console.log(`value=${value},index=${index}`);
    },
    myemojiClick() {
      this.popMyemojiShow = true;
    },
    emojiClick() {
      this.popShow = true;
    },
    emojiItemClick(item) {
      console.log(item);
      const input = this.$refs.input.$el.getElementsByTagName("textarea")[0];
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const text = `[${item.name}]`;
      this.content =
        this.content.substring(0, start) + text + this.content.substring(end);
      setTimeout(() => {
        input.focus();
        input.selectionStart = start + text.length;
        input.selectionEnd = start + text.length;
      }, 100);
    },
    myemojiItemClick(item) {
      console.log(item);
      var image = new window.Image();
      image.src = item.url;
      image.onload = () => {
        var width = image.width;
        var height = image.height;
        this.connect("发送中...");
        this.popMyemojiShow = false;
        this.sendData(
          {
            type: "image",
            image: {
              uri: item.url,
              width: width / 2,
              height: height / 2,
            },
            isEmoji: true,
          },
          () => {
            this.popMyemojiShow = false;
          },
          () => {
            this.popMyemojiShow = false;
          }
        );
      };
    },
    getMyemoji() {
      myemoji(this.token).then((res) => {
        if (res.code == 200) {
          this.myemoji = res.result.data;
          console.log(this.myemoji);
        }
      });
    },
    beforeUpload(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 600,
          maxHeight: 1000,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    uploadImage(success, fail) {
      this.image[0].status = "uploading";
      this.image[0].message = "上传中...";

      const file = this.image[0].file;
      let param = new FormData();
      param.append("file", file, file.name);

      this.$axios
        .post(
          `https://chat-upload.zuotutu.com/image.php?token=${encodeURIComponent(
            this.token
          )}&sign_date=${this.sign_date}&sign=${this.sign}`,
          param
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            typeof success == "function" && success(res);
          } else {
            Notify({
              type: "danger",
              message: res.data.info,
            });
            typeof fail == "function" && fail(res);
          }
        })
        .catch((res) => {
          Notify({
            type: "danger",
            message: res.message,
          });
          typeof fail == "function" && fail(res);
        });
    },
    send(type) {
      switch (type) {
        case "text":
          this.sendText();
          break;
        case "image":
          this.showImageDialog = true;
          break;
        case "video":
          this.showVideoDialog = true;
          break;
        case "transfer":
          this.showTransferDialog = true;
          break;
        case "redpacket":
          this.showRedpacketDialog = true;
          break;
        case "card":
          this.showCardDialog = true;
          break;
        case "file":
          this.showFileDialog = true;
          break;
        case "location":
          this.popMapShow = true;
          break;
      }
    },
    sendData(data, success, error) {
      send(this.token, data)
        .then((res) => {
          if (res.code == 200) {
            this.content = "";
            this.loading = false;
            Notify({
              type: "success",
              message: res.message,
            });
            typeof success == "function" && success(res);
          } else {
            Notify({
              type: "danger",
              message: res.message,
            });
            this.loading = false;
            typeof error == "function" && error(res.message);
          }
        })
        .catch((res) => {
          Notify({
            type: "danger",
            message: res.message,
          });
          typeof error == "function" && error(res.message);
        });
    },
    sendText() {
      if (!this.content) {
        Notify({
          type: "danger",
          message: "请输入消息内容",
        });
        return;
      } else if (this.content.length > 1000) {
        Notify({
          type: "danger",
          message: "消息内容不能超过1000个字符",
        });
        return;
      }

      this.connect("发送中...");
      this.sendData({
        type: "text",
        content: this.content,
      });
    },
    sendTransfer(action, done) {
      if (action == "confirm") {
        if (!this.money) {
          Notify({
            type: "danger",
            message: "请输入转账金额",
          });
          done(false);
          return;
        }
        this.sendData(
          {
            type: "transfer",
            money: this.money,
            transferText: "请收款",
            transferRemark: this.remark,
          },
          () => {
            this.money = "";
            this.remark = "";
            done();
          },
          () => {
            done(false);
          }
        );
      } else {
        done();
      }
    },
    sendRedpacket(action, done) {
      if (action == "confirm") {
        if (!this.money) {
          Notify({
            type: "danger",
            message: "请输入红包金额",
          });
          done(false);
          return;
        }
        this.sendData(
          {
            type: "redpacket",
            money: this.money,
            redpacketText: this.remark,
          },
          () => {
            this.money = "";
            this.remark = "";
            done();
          },
          () => {
            done(false);
          }
        );
      } else {
        done();
      }
    },
    sendImage(action, done) {
      if (action == "confirm") {
        if (!this.image.length) {
          done(false);
          Notify({
            type: "danger",
            message: "请选择图片",
          });
        } else {
          this.uploadImage(
            (res) => {
              this.sendData(
                {
                  type: "image",
                  image: {
                    uri: res.data.data.path,
                    width: res.data.data.width,
                    height: res.data.data.height,
                  },
                },
                () => {
                  this.image = [];
                  done();
                },
                () => {
                  this.image[0].status = "";
                  this.image[0].message = "";
                  done(false);
                }
              );
            },
            () => {
              done(false);
            }
          );
        }
      } else {
        done();
      }
    },
    sendVideo(action, done) {
      if (action == "confirm") {
        if (!this.image.length) {
          done(false);
          Notify({
            type: "danger",
            message: "请选择图片",
          });
        } else {
          this.uploadImage(
            (res) => {
              this.sendData(
                {
                  type: "video",
                  image: {
                    uri: res.data.data.path,
                    width: res.data.data.width,
                    height: res.data.data.height,
                  },
                  time: this.time,
                },
                () => {
                  this.image = [];
                  done();
                },
                () => {
                  this.image[0].status = "";
                  this.image[0].message = "";
                  done(false);
                }
              );
            },
            () => {
              done(false);
            }
          );
        }
      } else {
        done();
      }
    },
    sendCard(action, done) {
      if (action == "confirm") {
        if (!this.image.length) {
          done(false);
          Notify({
            type: "danger",
            message: "请选择图片",
          });
        } else if (!this.nickname) {
          done(false);
          Notify({
            type: "danger",
            message: "请输入名称",
          });
        } else {
          this.uploadImage(
            (res) => {
              this.sendData(
                {
                  type: "namecard",
                  nickname: this.nickname,
                  image: {
                    uri: res.data.data.path,
                    width: res.data.data.width,
                    height: res.data.data.height,
                  },
                  wechat: this.wechat,
                  isGet: this.cardType == "1" ? true : false,
                },
                () => {
                  this.image = [];
                  this.nickname = "";
                  this.wechat = "";
                  done();
                },
                () => {
                  this.image[0].status = "";
                  this.image[0].message = "";
                  done(false);
                }
              );
            },
            () => {
              done(false);
            }
          );
        }
      } else {
        done();
      }
    },
    sendFile(action, done) {
      if (action == "confirm") {
        if (this.fileTypeIndex === null) {
          done(false);
          Notify({
            type: "danger",
            message: "请选择文件类型",
          });
        } else if (!this.nickname) {
          done(false);
          Notify({
            type: "danger",
            message: "请输入文件名称",
          });
        } else if (!this.size) {
          done(false);
          Notify({
            type: "danger",
            message: "请输入文件大小",
          });
        } else {
          const _fileTypeName = [
            "doc",
            "xls",
            "pdf",
            "ppt",
            "music",
            "txt",
            "other",
            "zip",
          ];
          const _fileSize = ["B", "KB", "MB"];

          this.sendData(
            {
              type: "file",
              fileName: this.nickname,
              fileType: _fileTypeName[this.fileTypeIndex],
              fileSize: this.size + _fileSize[this.sizeType],
              showTrans: this.isPc ? true : false,
            },
            () => {
              this.nickname = "";
              this.size = "";
              this.sizeType = "1";
              done();
            },
            () => {
              done(false);
            }
          );
        }
      } else {
        done();
      }
    },
    sendMap() {
      if (!this.location) {
        Notify({
          type: "danger",
          message: "请选择位置",
        });
        return;
      }
      var location = this.location.latlng.lat + "," + this.location.latlng.lng;
      var mapImage =
        "https://apis.map.qq.com/ws/staticmap/v2/?center=" +
        location +
        "&zoom=15&size=600*200&maptype=roadmap&key=4UGBZ-62SWP-HJRDU-V6Q4W-HS4XQ-5EFKG&scale=2";

      console.log(this.location);

      this.connect("发送中...");

      this.sendData(
        {
          type: "map",
          linkTitle: this.location.poiname,
          linkDesc: this.location.poiaddress,
          linkImage: {
            uri: mapImage,
          },
        },
        () => {
          this.popMapShow = false;
        }
      );
    },
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less">
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
  }
}
.btnEmoji,
.btnMyEmoji {
  position: absolute;
  width: 28px;
  height: 28px;
  background: url("~@/assets/icons/emoji.png");
  background-size: 28px;
  right: 10px;
  bottom: 10px;
  text-indent: -99999px;
}
.btnMyEmoji {
  background-image: url("~@/assets/icons/myemoji.png");
  right: 50px;
  width: 30px;
  height: 30px;
  background-size: 30px;
}
.van-grid-item__content {
  padding: 8px;
}
.ifm {
  border: none;
  width: 100%;
  height: 100%;
}
.mapBtns {
  position: absolute;
  top: 60px;
  right: 10px;
}
.btn-send,
.btn-send .van-grid-item__text {
  color: #8bc34a;
}
.weixin-guid {
  text-align: right;
}
</style>
