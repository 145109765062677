/*
 * @Author: mars
 * @Date: 2022-02-27 16:43:36
 */
import request from '../utils/request'

const api = {
  auth: '/auth',
  send: '/send',
  myemoji: '/myemoji'
}

export default api

export function auth (token) {
  return request({
    url: api.auth,
    method: 'post',
    data: {
        token
    }
  })
}

export function send (token, data) {
  return request({
    url: api.send,
    method: 'post',
    data: {
      token,
      data
    }
  })
}

export function myemoji (token) {
  return request({
    url: api.myemoji,
    method: 'post',
    data: {
      token
    }
  })
}
